/* .App {
  text-align: center;
} */

li {
  list-style-type: none;
  padding: 10px;
}

.nav {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  background-color: aqua;
  padding: 15px;
}

.about__section {
  height: 1000px;
  width: 100%;
  background-image: url("../assets/tri.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.section__image {
  height: 500px;
  background-color: aqua;
  background-image: url();
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}